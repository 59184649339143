import {
  ElementType,
  WebComponentData,
} from '@wix/ambassador-app-service-webapp/types';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { IWebComponent } from '../../types/web-component';
import { TOKEN } from '../../consts';
import { getGlobals } from '../../utils/globals.utils';

export const filterPage = (components: IWebComponent[]) => {
  return components.filter(
    (comp) => (comp.data as WebComponentData).type === ElementType.PAGE,
  );
};

export const filterWidgets = (components: IWebComponent[]) => {
  return components.filter(
    (comp) =>
      (comp.data as WebComponentData)?.type === ElementType.WIDGET ||
      (comp.data as WebComponentData)?.type === undefined,
  );
};

export async function findComponentsByType(
  parentRef: ComponentRef,
  componentType: string,
) {
  const { editorSDK } = getGlobals();
  const children = await editorSDK.document.components.getChildren(TOKEN, {
    componentRef: parentRef,
    recursive: true,
  });

  const result: ComponentRef[] = [];

  await Promise.all(
    children.map(async (child) => {
      const type = await editorSDK.components.getType(TOKEN, {
        componentRef: child,
      });

      if (type === componentType) {
        result.push(child);
      }
    }),
  );

  return result;
}

export const findPagesThatHaveNotBeenInstalledInThePast = async (
  components: IWebComponent[],
) => {
  const { editorSDK } = getGlobals();
  const pages = await editorSDK.pages.data.getAll('');
  const flatTpaPagesId = pages.map((page) => page.tpaPageId);

  return components.filter(
    (component) =>
      !flatTpaPagesId.includes(
        `${component.data?.tagName}|${component.componentId}`,
      ),
  );
};
