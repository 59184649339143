import { FlowEditorSDK, IHttpClient } from '@wix/yoshi-flow-editor';
import { TOKEN } from '../consts';
import { getGlobals } from './globals.utils';

export async function getMetaSiteCacheData({
}: {
  editorSDK: FlowEditorSDK;
  httpClient: IHttpClient;
}): Promise<any> {
  const { editorSDK, httpClient } = getGlobals();

  try {
    const metaSiteId: string = await editorSDK.document.info.getMetaSiteId(
      TOKEN
    );
    const cacheData: any =
      (
        await httpClient.get(
          `https://editor.wix.com/_serverless/deep-link-editor-cache/data/${metaSiteId}`
        )
      )?.data ?? {};
    return cacheData?.data?.data;
  } catch (e) {
    return undefined;
  }
}

export async function deleteMetaSiteCacheData(): Promise<void> {
  const { editorSDK, httpClient } = getGlobals();

  try {
    const metaSiteId: string = await editorSDK.document.info.getMetaSiteId(
      TOKEN
    );

    const deleteResult: any = await httpClient.delete(
      `https://editor.wix.com/_serverless/deep-link-editor-cache/data/${metaSiteId}`
    );

    if (deleteResult?.status === 200 && deleteResult?.data?.status === 204) {
      return;
    }

    return;
  } catch (e) {
    return;
  }
}
