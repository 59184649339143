import { managedApps } from '@wix/ambassador-devcenter-marketplace-v1-managed-web-solution/http';
import { getGlobals } from './globals.utils';

const GENERAL_APPLICATION_ID = '22bef345-3c5b-4c18-b782-74d4085112ff';

export async function isEligibleForUpgrade(): Promise<boolean> {
  const { editorSDK, httpClient, appData: { appDefinitionId } } = getGlobals();

  if (!editorSDK) {
    return false;
  }

  const generalApp = await editorSDK.document.tpa.app.getDataByAppDefId(
    GENERAL_APPLICATION_ID,
    GENERAL_APPLICATION_ID,
  );
  const generalInstance = generalApp.instance;
  try {
    const { data } = await httpClient.request(managedApps({}), {
      signedInstance: generalInstance,
    });
    const managedWebSolutions = data?.managedWebSolutions ?? [];
    return managedWebSolutions.some(
      (item) =>
        item?.webSolutionBase?.id === appDefinitionId && item.eligibleForUpgrade,
    );
  } catch (error) {
    console.error('Error fetch managed apps');
    return false;
  }
}
