import { IWebComponent } from '../../types/web-component';
import {
  install,
} from './install-editor-actions';
import {
  filterPage,
  filterWidgets,
  findPagesThatHaveNotBeenInstalledInThePast,
} from './install.utils';

export const firstInstallFlow = async ({
  webComponents,
}: {
  webComponents: IWebComponent[];
}) => {
  const webComponentsPages = filterPage(webComponents as IWebComponent[]);
  const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
    webComponentsPages as IWebComponent[],
  );
  const widgetsToAdd = pagesToAdd.length ? [] : filterWidgets(webComponents as IWebComponent[]);

  await install({
    pagesToAdd,
    widgetsToAdd,
  });
};
