import { ComponentRef } from '@wix/yoshi-flow-editor';
import { IWebComponent } from '../../types/web-component';
import { TOKEN } from '../../consts';
import { getGlobals } from '../../utils/globals.utils';

export const generateURL = (url: string | undefined, instanceId: string) =>
  `${url?.indexOf('?') !== -1
    ? `${url}&instanceId=${instanceId}`
    : `${url}?instanceId=${instanceId}`
  }`;

export const getFieldsToUpdate = async ({
  webComponent,
  currentComponentData,
}: {
  webComponent: IWebComponent;
  currentComponentData: {
    initialAttributes: string;
    url: string;
    tagName: string;
  };
}) => {
  const { instanceId } = getGlobals();
  const scriptTag = webComponent.data?.scriptTag;
  const tagName = webComponent.data?.tagName;
  const url = generateURL(scriptTag, instanceId);

  const isSameAppInstanceId =
    currentComponentData?.initialAttributes.includes(instanceId);

  const shouldUpdateUrl =
    !!currentComponentData?.url && currentComponentData?.url !== url;

  const shouldUpdateTagName =
    !!currentComponentData?.tagName &&
    currentComponentData?.tagName !== tagName;

  if (isSameAppInstanceId) {
    const fieldsToUpdate = {
      ...(shouldUpdateTagName ? { tagName } : {}),
      ...(shouldUpdateUrl ? { url } : {}),
    };

    return fieldsToUpdate;
  }
};

const getAppWidgetComponent = async ({
  component,
}: {
  component: ComponentRef;
}) => {
  const { editorSDK } = getGlobals();
  const ancestors = await editorSDK.components.getAncestors(TOKEN, {
    componentRef: component,
  });

  const appWidgetComponent = ancestors.find(async (ancestor) => {
    const controllerConnections =
      await editorSDK.controllers.getControllerConnections(TOKEN, {
        controllerRef: ancestor,
      });

    return controllerConnections.some(
      (controllerConnection) =>
        controllerConnection.connection.role === 'webComponent' &&
        controllerConnection.componentRef.id === component.id,
    );
  });

  return appWidgetComponent;
};

export const updateComponentsData = ({
  webComponents,
  components,
}: {
  webComponents: IWebComponent[];
  components: ComponentRef[];
}) => {
  const { editorSDK } = getGlobals();
  components.map(async (component) => {
    const appWidgetComponent = await getAppWidgetComponent({
      component,
    });

    if (appWidgetComponent) {
      const data = await editorSDK.document.controllers.getData(TOKEN, {
        controllerRef: appWidgetComponent,
      });

      const webComponent = webComponents.find(
        (comp) => comp.componentId === data.type,
      );

      if (webComponent) {
        const currentComponentData =
          (await editorSDK.document.components.data.get(TOKEN, {
            componentRef: component,
          })) as { initialAttributes: string; url: string; tagName: string };

        const fieldsToUpdate = getFieldsToUpdate({
          webComponent,
          currentComponentData,
        });

        Object.keys(fieldsToUpdate).length > 0 &&
          (await editorSDK.document.components.data.update(TOKEN, {
            componentRef: component,
            data: fieldsToUpdate,
          }));
      }
    }
  });
};
