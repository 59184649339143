import { DeviceType, EventType } from '@wix/platform-editor-sdk';
import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { addMyBusinessPanelConfigurationToHotels } from './app-specific-logic/hotels/hotels-my-business-panel-config';
import { HOTELS_APP_IDS } from './app-specific-logic/hotels/hotels.constants';
import { configureWidgetManifest } from './components/webComponent/manifest';
import {
  customElementComponentType,
  DASHBOARD_ACTION_ID,
  SETTINGS_ACTION_ID,
  TOKEN,
} from './consts';
import { firstInstallFlow } from './lib/installation-flows/first-install-flow';
import { installFromMetaSiteData } from './lib/installation-flows/meta-site-data-install';
import {
  getLastConsentedOrigin,
  setFirstTimeInstanceHistory,
} from './lib/lifecycle-flows/instance-history';
import { updateComponentsData } from './lib/lifecycle-flows/update-component';
import { updateInstanceId } from './lib/lifecycle-flows/update-instance-id';
import { updateViewMode } from './lib/lifecycle-flows/update-view-mode';
import { openDashboard } from './lib/panel-flows/dashboard';
import {
  openSettingsPanel,
  reportBiEventGfppSettingsClicked,
  saveSettingsToThirdPartyService,
} from './lib/panel-flows/settings';
import { IWebComponent } from './types/web-component';
import { initGlobals } from './utils/globals.utils';
import {
  deleteMetaSiteCacheData,
} from './utils/metasite.utils';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  editorOptions,
  flowApi
) => {
  const { appData, instanceId, originInstanceId, metaSiteData } = await initGlobals({
    flowApi,
    appDefinitionId,
    sdk: editorSDK,
  });

  const { firstInstall } = editorOptions;
  const isDuplicatedSite = Boolean(originInstanceId);
  const isHotels = HOTELS_APP_IDS.includes(appDefinitionId);

  const webComponents: IWebComponent[] =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  if (isDuplicatedSite && isHotels) {
    try {
      // Find the last consented instance of the origin ID
      const lastConsentedOriginId = await getLastConsentedOrigin() || originInstanceId;

      // set the current instance with the last consented ID
      if(lastConsentedOriginId !== instanceId) {
        setFirstTimeInstanceHistory({
          instanceId,
          originInstanceId,
          lastConsentedOriginId,
        });
  
        await updateInstanceId({
          instanceId,
          originInstanceId: lastConsentedOriginId,
        });
      }
    } catch (error) {
      console.error('error updating instance id for duplicate site flow ', {
        error,
      });
    }
  }

  // update the instance-id with the current instance-id in order to solve a bug when duplicating a site also duplicates the instance-id
  if (metaSiteData) {
    await deleteMetaSiteCacheData();

    installFromMetaSiteData({
      webComponents,
    });
  }

  if (!metaSiteData && firstInstall) {
    firstInstallFlow({
      webComponents,
    });
  };

  const customElementComps = await editorSDK.document.components.findAllByType(
    TOKEN,
    {
      componentType: customElementComponentType,
    }
  );

  // update changes of custom element components (scriptTag)
  updateComponentsData({
    webComponents,
    components: customElementComps,
  });

  editorSDK.addEventListener(EventType.siteWasSaved, async (e) => {
    const currentInstanceId = instanceId;
    const currentOriginInstanceId = originInstanceId;
    const { instanceId: newInstanceId, originInstanceId: newOriginInstanceId } = await editorSDK.document.info.getAppInstancePayload(TOKEN);

    if(isHotels && currentInstanceId !== newInstanceId) {
      const lastConsentedOriginId = await getLastConsentedOrigin() || currentOriginInstanceId || currentInstanceId;

      setFirstTimeInstanceHistory({
        instanceId: newInstanceId,
        originInstanceId: newOriginInstanceId,
        lastConsentedOriginId,
      });

      await updateInstanceId({
        instanceId: newInstanceId,
        originInstanceId: lastConsentedOriginId,
      });
    }
  });

  editorSDK.addEventListener(EventType.widgetGfppClicked, async (event) => {
    const { detail } = event;

    if (detail.id === DASHBOARD_ACTION_ID) {
      openDashboard();
    }

    if (detail.id === SETTINGS_ACTION_ID) {
      // const { demoMode } = await editorSDK.document.info.getAppInstancePayload(
      //   TOKEN
      // );

      // if (demoMode) {
      //   editorSDK.editor.showNotification(TOKEN, {
      //     title: 'Save and Refresh',
      //     message:
      //       'You need to save and refresh the editor in order to change the settings or consent to the app',
      //     type: 'error',
      //     link: { caption: '' },
      //   });
      // } else {
      // }

      openSettingsPanel(detail.componentRef);
      reportBiEventGfppSettingsClicked(
        flowApi,
        event,
        webComponents
      );
    }
  });

  editorSDK.addEventListener(EventType.switchedToMobileView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      }
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        mode: DeviceType.Mobile,
        compRef,
      });
    }
  });

  editorSDK.addEventListener(EventType.switchedToDesktopView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      }
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        mode: DeviceType.Desktop,
        compRef,
      });
    }
  });

  editorSDK.addEventListener(EventType.siteWasPublished, async () => {
    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToThirdPartyService({
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            componentId: component.componentId,
            instanceId: appData.instanceId,
            status: 'published',
          },
        });
    }
  });

  editorSDK.addEventListener(EventType.themeChanged, async (e) => {
    // @ts-expect-error
    if (e?.detail?.changeType === 'STYLE') {
      return;
    }

    const [colors, fonts] = await Promise.all([
      editorSDK.document.theme.colors.getAll(TOKEN),
      editorSDK.document.theme.fonts.getMap(TOKEN),
    ]);

    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToThirdPartyService({
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            data: {},
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
    }

    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      }
    );

    for await (const compRef of componentsByType) {
      updateViewMode({
        compRef,
        reset: true,
      });
    }
  });

  editorSDK.addEventListener(
    EventType.anyComponentAddedToStage,
    async (data) => {
      const fullData: any = await editorSDK.components.data.get(TOKEN, {
        componentRef: data.detail.compRef,
      });
      // controllerType is the component Id
      const { applicationId, controllerType } = fullData;

      const appDataToUpdate: any =
        await editorSDK.document.tpa.app.getDataByAppDefId(
          applicationId,
          applicationId
        );
      const component: any = appDataToUpdate.components?.find(
        (comp: any) => comp.componentId === controllerType
      );

      if (component.data?.gfppSettings?.fetchInitialData) {
        const [colors, fonts] = await Promise.all([
          editorSDK.document.theme.colors.getAll(TOKEN),
          editorSDK.document.theme.fonts.getMap(TOKEN),
        ]);

        saveSettingsToThirdPartyService({
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
      }
    }
  );
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  { initialAppData },
  flowApi
) => {
  const { appDefinitionId } = initialAppData;
  const {
    essentials: { httpClient },
  } = flowApi;

  await configureWidgetManifest(
    appManifestBuilder,
  );

  if (HOTELS_APP_IDS.includes(appDefinitionId)) {
    addMyBusinessPanelConfigurationToHotels({
      appManifestBuilder,
      initialAppData,
    });
  }

  // configure page options
  return appManifestBuilder
    .configurePages((pagesBuilder) => {
      // page settings
      pagesBuilder
        .addSettingsTab(
          {
            title: 'Page Info',
            helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
            type: 'page_info',
          },
          {
            title: 'Layout',
            type: 'layout',
          },
          {
            title: 'Permissions',
            type: 'permissions',
          },
          {
            title: 'SEO',
            helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
            type: 'seo',
          }
        )
        // page actions
        .addAction(
          {
            type: 'page_rename',
          },
          {
            type: 'page_delete',
            onClick: async () => {
              await editorSDK.application.uninstall(TOKEN, {
                openConfirmation: true,
              });
            },
          }
        );
    })
    .build();
};
