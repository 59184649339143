import { customElementComponentType, TOKEN } from '../../consts';
import { getGlobals } from '../../utils/globals.utils';

export async function updateInstanceId({
  instanceId,
  originInstanceId,
}: {
  instanceId: string;
  originInstanceId: string;
}): Promise<void> {
  const { editorSDK } = getGlobals();

  const customElementComponentRefs =
    await editorSDK.document.components.findAllByType(TOKEN, {
      componentType: customElementComponentType,
    });

  for (const customElementComponentRef of customElementComponentRefs) {
    const componentData = await editorSDK.components.data.get(TOKEN, {
      componentRef: customElementComponentRef,
    });

    // @ts-expect-error
    const initialAttributes = JSON.parse(componentData.initialAttributes);

    const wixConfig = JSON.parse(initialAttributes.wixconfig);

    const newWixConfig = {
      ...wixConfig,
      instanceId,
      originInstanceId,
    };

    const newInitialAttributes = {
      ...initialAttributes,
      wixconfig: JSON.stringify(newWixConfig),
    };

    // @ts-expect-error
    componentData.initialAttributes = JSON.stringify(newInitialAttributes);

    // @ts-expect-error
    const url = new URL(componentData.url);
    url.searchParams.delete('instanceId');
    url.searchParams.append('instanceId', instanceId);

    // @ts-expect-error
    componentData.url = url.toString();

    editorSDK.components.data.update(TOKEN, {
      componentRef: customElementComponentRef,
      data: componentData,
    });
  }
}
