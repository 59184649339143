import { FlowAPI, FlowEditorSDK, IHttpClient } from '@wix/yoshi-flow-editor';
import { TOKEN } from '../consts';
import { AppData } from '../types/web-component';
import { getMetaSiteCacheData } from './metasite.utils';

let httpClient: IHttpClient;
let editorSDK: FlowEditorSDK;
let appData: AppData;
let instanceId: string;
let originInstanceId: string;
let metaSiteData: any;

export interface InitGlobalsRequest {
  flowApi: FlowAPI;
  appDefinitionId: string;
  sdk: FlowEditorSDK;
};

export async function initGlobals({
  flowApi,
  appDefinitionId,
  sdk,
}: InitGlobalsRequest) {
  const client = flowApi.essentials.httpClient;
  const { instanceId: instId, originInstanceId: originId } = await sdk.document.info.getAppInstancePayload(TOKEN);
  const msData = await getMetaSiteCacheData({
    editorSDK: sdk,
    httpClient: client,
  });
  const data: AppData = await sdk.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId
  );

  httpClient = client;
  editorSDK = sdk;
  appData = data;
  instanceId = instId;
  originInstanceId = originId;
  metaSiteData = msData;

  return {
    httpClient,
    editorSDK,
    appData,
    instanceId,
    originInstanceId,
    metaSiteData,
  };
};

export function initViewerGlobals({
  client,
  instance,
}: {
  client: IHttpClient;
  instance: string;
}) {
  httpClient = client;
  instanceId = instance;
}

export function getGlobals() {
  return {
    httpClient,
    editorSDK,
    appData,
    instanceId,
    originInstanceId,
    metaSiteData,
  };
};

export function setGlobalInstanceId({
  id,
}: {
  id: string;
}) {
  instanceId = id;
};