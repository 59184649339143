import { HttpResponse } from '@wix/yoshi-flow-editor';
import { getGlobals } from '../../utils/globals.utils';

export const INSTANCE_HISTORY_SERVER_BASE_URL =
  'https://editor.wix.com/_serverless/instance-id-history/';

export const SET_INSTANCE_END_POINT = 'set-instance-history';

export const GET_INSTANCE_END_POINT = 'get-instance-history';

export interface InstanceHistoryItem {
  instanceId: string;
  originId: string;
  lastConsentedOriginId: string;
  key: string;
}

export const setFirstTimeInstanceHistory = async ({
  lastConsentedOriginId,
  originInstanceId,
  instanceId,
}: {
  lastConsentedOriginId?: string;
  originInstanceId?: string;
  instanceId: string;
}) => {
  const { httpClient } = getGlobals();

  // Can decide to not await for this
  try {
    const response = await httpClient.post<InstanceHistoryItem>(
      `${INSTANCE_HISTORY_SERVER_BASE_URL}${SET_INSTANCE_END_POINT}`,
      {
        key: instanceId,
        originInstanceId: originInstanceId ?? instanceId,
        lastConsentedOriginId: lastConsentedOriginId ?? instanceId,
        instanceId,
      }
    );
    return response.data;
  } catch (err) {
    console.error('error setting the insance history', {
      err,
    });
  }
};

export const getLastConsentedOrigin = async (): Promise<string | undefined> => {
  const { httpClient, instanceId, originInstanceId } = getGlobals();

  // First check if the instance has already been updated and has a lastCOnsented of itself, if not, check the origin ID. return the last consented of them
  try {
    const responseFromInstance: HttpResponse<InstanceHistoryItem> =
      await httpClient.get(
        `${INSTANCE_HISTORY_SERVER_BASE_URL}${GET_INSTANCE_END_POINT}/${instanceId}`
      );
    if (responseFromInstance?.data?.lastConsentedOriginId !== undefined) {
      return responseFromInstance?.data?.lastConsentedOriginId;
    } else {
      const responseFromOrigin: HttpResponse<InstanceHistoryItem> =
        await httpClient.get(
          `${INSTANCE_HISTORY_SERVER_BASE_URL}${GET_INSTANCE_END_POINT}/${originInstanceId}`
        );
      return responseFromOrigin?.data?.lastConsentedOriginId;
    }
  } catch (err) {
    console.error('error getting instance history', {
      err,
    });
  }
};
