import { WebComponentData } from '@wix/ambassador-app-service-webapp/types';
import { ColorPalete, PageRef } from '@wix/platform-editor-sdk';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { customElementComponentType, TOKEN } from '../../consts';
import { IWebComponent, TFont } from '../../types/web-component';
import { getGlobals } from '../../utils/globals.utils';
import { checkPageSlug } from '../../utils/slug-check';
import { saveSettingsToThirdPartyService } from '../panel-flows/settings';
import { getCustomElementStructure } from './get-custom-element-structure';
import { findComponentsByType } from './install.utils';

export const openWidgetSelectorModal = async () => {
  const { editorSDK } = getGlobals();
  const metaSiteId = await editorSDK.document.info.getMetaSiteId(TOKEN);
  const editorSDKVersion = editorSDK.info.getSdkVersion(TOKEN);

  editorSDK.editor.openModalPanel(TOKEN, {
    url: `https://www.wix.com/_serverless/dynamic-settings-renderer?wix-sdk-version=${editorSDKVersion.scriptSrc}&openWidgetsModal=true&componentId=not_exists&metaSiteId=${metaSiteId}`,
    shouldHideHeader: true,
    width: 680,
    height: 423,
  });
};

export const createNewPage = async ({
  componentDefinition,
}: {
  componentDefinition: IWebComponent;
}) => {
  const { editorSDK, appData: { appDefinitionId } } = getGlobals();
  const {
    showPageInMenu = false,
    tagName,
    slug,
    modalTitle,
  } = componentDefinition.data as WebComponentData;
  const pages = await editorSDK.pages.data.getAll('');

  const validPageSlug = checkPageSlug(
    slug as string,
    pages.map((page) => page.pageUriSEO)
  );
  return editorSDK.pages.add(TOKEN, {
    title: modalTitle ?? (tagName as string),
    shouldAddMenuItem: showPageInMenu,
    shouldNavigateToPage: true,
    definition: {
      type: 'Page',
      componentType: 'mobile.core.components.Page',
      data: {
        tpaPageId: `${tagName}${componentDefinition.componentId
          ? `|${componentDefinition.componentId}`
          : ``
          }`,
        managingAppDefId: appDefinitionId,
        pageUriSEO: validPageSlug || tagName,
        metaData: {
          pageId: tagName as string,
        },
      },
    },
  });
};

export async function addComponent({
  fullWidth = false,
  componentDefinition,
  pageRef,
}: {
  componentDefinition: any;
  fullWidth?: boolean;
  pageRef: PageRef;
}) {
  const { editorSDK } = getGlobals();
  const componentRef = await editorSDK.document.components.add(TOKEN, {
    componentDefinition,
    pageRef,
  });
  return componentRef;
}

export const addComponentToPage = async ({
  componentDefinition,
  fullWidth = false,
  isPage = false,
  pageRef,
}: {
  componentDefinition: IWebComponent;
  fullWidth?: boolean;
  isPage?: boolean;
  pageRef: PageRef;
}) => {
  const { editorSDK, metaSiteData, instanceId } = getGlobals();
  const formFactor = await editorSDK.editor.info.getEditorMode(TOKEN);
  const fonts: Record<string, TFont> =
    await editorSDK.document.theme.fonts.getMap(TOKEN);
  const colors: ColorPalete = await editorSDK.document.theme.colors.getAll(
    TOKEN
  );

  // const colorForAttr = Object.keys(colors)
  //   .filter((key) => ['color_1', 'color_3', 'color_4', 'color_5', 'color_8'].includes(key))
  //   .reduce((obj, key) => {
  //     return Object.assign(obj, {
  //       [key]: colors[key as keyof ColorPalete]
  //     });
  //   }, {});

  const colorsForAttr = {
    'color_0': colors['color_1'],
    'color_1': colors['color_3'],
    'color_2': colors['color_4'],
    'color_3': colors['color_5'],
    'color_4': colors['color_8'],
  };

  const fontsForAttr = {
    'font_0': {
      'family': fonts['font_2'].family,
      'color': fonts['font_2'].cssColor,
      'bold': fonts['font_2'].bold,
    },
    'font_1': {
      'family': fonts['font_8'].family,
      'color': fonts['font_8'].cssColor,
      'bold': fonts['font_8'].bold,
    },
    'font_2': {
      'family': fonts['font_9'].family,
      'color': fonts['font_9'].cssColor,
      'bold': fonts['font_9'].bold,
    },
  }

  const customElementComponentRef = await addComponent({
    componentDefinition: getCustomElementStructure({
      data: componentDefinition.data ?? {},
      formFactor,
      widgetId: componentDefinition.componentId,
      isPage,
      theme: JSON.stringify({
        colors: colorsForAttr,
        fonts: fontsForAttr,
      })
    }),
    fullWidth,
    pageRef,
  });

  const currentElementRef = await findComponentsByType(
    customElementComponentRef,
    customElementComponentType
  );

  await editorSDK.document.controllers.saveConfiguration(TOKEN, {
    controllerRef: customElementComponentRef,
    config: { componentId: componentDefinition.componentId, ...metaSiteData },
  });

  await editorSDK.document.controllers.connect(TOKEN, {
    controllerRef: customElementComponentRef,
    connectToRef: currentElementRef[0],
    role: 'webComponent',
    connectionConfig: { time: new Date().getTime() },
  });
  // if fetchInitialData is set we will send there initial data + theme
  componentDefinition.data?.gfppSettings?.fetchInitialData &&
    saveSettingsToThirdPartyService({
      url: componentDefinition.data?.gfppSettings?.fetchInitialData,
      settings: {
        data: {},
        componentId: componentDefinition.componentId,
        instanceId,
        theme: {
          colors,
          fonts,
        },
      },
    });
};

export async function installPages({
  pagesToAdd,
}: {
  pagesToAdd: IWebComponent[];
}) {
  const { editorSDK } = getGlobals();

  for (const componentDefinition of pagesToAdd) {
    const pageRef = await createNewPage({
      componentDefinition,
    });

    await addComponentToPage({
      pageRef,
      componentDefinition,
      fullWidth: true,
      isPage: true,
    });
  }

  pagesToAdd.length > 1 &&
    (await editorSDK.pages.navigateTo(TOKEN, {
      pageRef: { id: pagesToAdd[0].componentId as string },
    }));
}

export async function install({
  pagesToAdd = [],
  widgetsToAdd = [],
}: {
  pagesToAdd?: IWebComponent[];
  widgetsToAdd?: IWebComponent[];
}) {
  const { editorSDK } = getGlobals();

  if (pagesToAdd.length) {
    await installPages({
      pagesToAdd,
    });
  }

  if (widgetsToAdd.length === 1) {
    const pageRef = await editorSDK.pages.getCurrent(TOKEN);

    await addComponentToPage({
      componentDefinition: widgetsToAdd[0],
      pageRef,
    });
  } else if (widgetsToAdd.length > 1) {
    openWidgetSelectorModal();
  }
}
